<template>
  <div class="CallInPeakByMonthContainerFlexColumn">
    <div class="CallInPeakByMonthContainerFlexColumn__top">
      <div class="title">呼入月话务高峰</div>
      <el-divider />
    </div>
    <dc-search-panel :params="queryParams" style="border: none; background-color: white; margin-bottom: 0" @search="doSearch" @reset="reset">
      <dc-form-item label="统计时间">
        <el-date-picker v-model="queryParams.startDate" type="month" placeholder="选择日期" value-format="yyyy-MM"></el-date-picker>
      </dc-form-item>
    </dc-search-panel>

    <div class="buttons">
      <el-button type="primary" @click="getNowMonth">当月</el-button>
      <el-button type="primary" @click="doSearch">查询</el-button>
      <el-button type="primary" @click="reset">重置</el-button>
      <el-button type="primary" @click="exportExcel">导出Excel</el-button>
    </div>

    <div id="dataChart" style="width: 100%; height: 300px; background-color: white; padding: 10px"></div>
    <div class="CallInPeakByMonthContainerFlexColumn__body">
      <dc-datatable ref="list" :url="apiUrl" :params="queryParams" :pagination="false" :show-summary="true" @loaded="loaded">
        <el-table-column prop="day" label="日期" align="center"></el-table-column>
        <el-table-column prop="answer_count" label="总话务量" align="center">
          <template #default="scope">
            {{ scope.row.total }}
          </template>
        </el-table-column>
        <el-table-column prop="answer_count" label="接听数" align="center"></el-table-column>
        <el-table-column prop="answer_count" label="接听率" align="center">
          <template #default="scope">
            {{ scope.row.answer_count_percent | getPercent }}
          </template>
        </el-table-column>
        <el-table-column prop="answer_lost_count" label="未接听数" align="center"> </el-table-column>
        <el-table-column prop="answer_lost_count" label="未接听率" align="center">
          <template #default="scope">
            {{ scope.row.answer_lost_count_percent | getPercent }}
          </template>
        </el-table-column>
      </dc-datatable>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import axios from "axios";
import { tansParams } from "@/api/device/device";
import { getToken } from "@/utils/auth";

export default {
  name: "CallInPeakByMonth",
  filters: {
    getPercent(val) {
      if (!val) return "0%";
      return Math.round(val * 10000) / 100 + "%";
    },
  },
  data() {
    return {
      apiUrl: `${this.baseUrls.callDataCount}/callInPeakByMonthReport`,
      queryParams: {},
    };
  },
  created() {
    this.resetPara();
  },
  methods: {
    loaded(data, cb) {
      let answer_total = 0;
      let answer_lost_total = 0;
      data.forEach((p) => {
        p.total = p.answer_count + p.answer_lost_count;
        p.answer_count_percent = p.answer_count / p.total;
        p.answer_lost_count_percent = p.answer_lost_count / p.total;
        answer_total += p.answer_count;
        answer_lost_total += p.answer_lost_count;
      });
      this.$nextTick(async () => {
        let map = { answer_total: answer_total, answer_lost_total: answer_lost_total };
        await this.showSummariesPosition(map);
      });
      this.drawLine(data);
      cb(data);
      // this.$refs.list.resetHeight();
    },
    // 合计行设置
    showSummariesPosition(val) {
      // 合计行显示在表头
      let table = document.querySelector(".el-table");
      let footer = document.querySelector(".el-table__footer-wrapper");
      let body = document.querySelector(".el-table__body-wrapper");
      table.removeChild(footer);
      table.insertBefore(footer, body);
      footer.style = "background-color: white";
      // 在合计行的最后一列添加按钮
      table.querySelectorAll("td")[0];
      let html = table.querySelectorAll("td")[0].querySelector(".cell");
      html.innerHTML = `合计`;
      table.querySelectorAll("td")[1].querySelector(".cell").innerHTML = val.answer_total + val.answer_lost_total;
      table.querySelectorAll("td")[2].querySelector(".cell").innerHTML = val.answer_total;
      table.querySelectorAll("td")[4].querySelector(".cell").innerHTML = val.answer_lost_total;
    },
    drawLine(data) {
      let day = [];
      let answerCount = [];
      let answerLostCount = [];
      data.forEach((p) => {
        day.push(p.day);
        answerCount.push(p.answer_count);
        answerLostCount.push(p.answer_lost_count);
      });
      let optionline = {
        title: {
          text: "呼入月话务高峰",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["接听数", "未接听数"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: day,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "接听数",
            type: "line",
            data: answerCount,
            color: "green",
          },
          {
            name: "未接听数",
            type: "line",
            data: answerLostCount,
            color: "red",
          },
        ],
      };
      //基于准本好的DOM，初始化echarts实例
      let dataChart = this.$echarts.init(document.getElementById("dataChart"));
      //绘制图表
      dataChart.setOption(optionline);
    },
    doSearch() {
      if (this.queryParams.startDate == "" || this.queryParams.startDate == undefined || this.queryParams.startDate == "undefined") {
        this.$message.error("日期不能为空！");
        return;
      }
      this.$refs.list.load();
    },
    getNowMonth() {
      // 获取当前月份的第一天
      this.queryParams.startDate = moment().format("YYYY-MM");
      this.doSearch();
    },
    reset() {
      this.resetPara();
      this.$nextTick(() => this.doSearch());
    },
    resetPara() {
      this.queryParams = {
        username: "",
        startDate: moment().format("YYYY-MM"),
      };
    },
    exportExcel() {
      let url = `${this.baseUrls.callDataCount}/callInPeakByMonthReportExcel`;
      let params = this.queryParams;
      axios
        .post(url, params, {
          transformRequest: [
            (params) => {
              return tansParams(params);
            },
          ],
          headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: getToken() },
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "呼入月话务量高峰.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },
  },
};
</script>
<style lang="scss">
.CallInPeakByMonthContainerFlexColumn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;

  overflow: hidden;
  .el-date-editor {
    width: 170px;
  }

  &__top {
    background-color: white;

    .title {
      font-size: 18px;
    }

    padding: 10px;

    .el-divider {
      margin: 12px 0;
    }
  }

  .buttons {
    text-align: right;
    clear: both;
    padding-right: 10px;
    background-color: white;
  }

  &__body {
    flex: 1;
    border-top: none;
    padding: 10px;
    background-color: white;
  }

  .table-cell {
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all; /* 可选，针对长单词或URL的自动断行 */
  }

  .el-table {
    display: flex;
    flex-direction: column;
  }
  .el-table__body-wrapper {
    order: 1;
  }
  .el-table__fixed-body-wrapper {
    top: 96px !important;
  }
  .el-table__fixed-footer-wrapper {
    z-index: 0;
  }
}
</style>
